<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>{{ parentEventName }}の子イベント一覧</template>
              <template v-slot:body>
                <div class="scrollX">
                  <Table
                    :dataTable="dataTable"
                    :labels="labels"
                    @handle-change="handleChange"
                    hasButtonColumns
                  ></Table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'SettingEvent' }"><i class="aikon aikon-arrow_left"></i>親イベント一覧に戻る</router-link>
          </li>
          <li class="listGrid-item pos-end">
            <ActionButton v-if="!hasOnlyOneEvent && !isClientChild" class="btn btn-white mr-8" :handle-submit="updateOrder" button-text="並び順変更" />
            <router-link
              v-if="$permission.isGmo()"
              class="btn btn-main"
              tag="button"
              :to="{ name: 'SettingEventChildRegister' }"
              :disabled="!parentEventDetail.isPossibleCreate"
            >新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//components
import Table from '@/components/Table.vue';
//mixins
import nav from '@/mixins/nav/setting';
import status from '@/mixins/plugin/status';
import event from '@/mixins/module/event';
//helpers
import { formatBetweenTwoDates } from '@/helpers/formatData';
//constants
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingEventChild',
  data: function () {
    return {
      pageName: '設定',
      itemList: [],
    };
  },
  computed: {
    ...mapGetters({
      parentEventDetail: 'event/parentEventDetail',
      childEventList: 'event/childEventList',
      userChildRoles: 'auth/userChildRoles',
      isClientChild: 'auth/isClientChild',
    }),
    parentEventName() {
      return this.parentEventDetail?.name || '';
    },
    hasOnlyOneEvent() {
      return this.childEventList?.length <= 1;
    },
    labels() {
      const data = {
        order: '並び順',
        name: '子イベント名',
        clients: '事務局名／企業名',
        date: '使用可能期間',
        status: 'ステータス',
      }
      if (this.hasOnlyOneEvent || this.isClientChild) delete data.order;
      return data;
    },
    dataTable() {
      return this.childEventList.map((item, index) => {
        return {
          ...item,
          order: {
            isInputType: true,
            type: 'tel',
            directive: 'number',
            value: item?.order,
            label: 'order',
            id: item?.id,
            order: item?.order,
            name: `events.${index}.order`,
          },
          name: this.childNameContent(item),
          clients: this.clientContent(item.clients),
          status: {
            content: item.status?.name,
            class: this.statusClass(item.status?.name),
          },
          date: formatBetweenTwoDates(item.usablePeriod.start, item.usablePeriod.end),
          linkButtonColumns: this.buttonLinkContent(item),
        };
      });
    },
  },
  components: {
    Table,
  },
  mixins: [nav, status, event],
  methods: {
    handleChange(order, data) {
      const changedItem = this.itemList?.find((_, index) =>  index + 1 == order);
      if (changedItem) changedItem.order = data;
    },
    async updateOrder() {
      const result = await this.$store.dispatch(`event/updateOrderChildEvent`, { events: this.itemList });
      if (result) {
        this.$message.showSuccess('sortSuccess');
        this.$store.dispatch('event/getChildEventList', { includeUnregistered: 1, limit: 20 });
        this.setItemList();
      }
    },
    setItemList() {
      this.itemList = this.childEventList.map(e => ({ id: e?.id, order: e?.order }));
    }
  },
  created() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('auth/getUserInfor'),
      this.$store.dispatch('event/getChildEventList', { includeUnregistered: 1, limit: 20 }),
      this.$store.dispatch('event/getParentEventDetail', this.$route.params.subdomain),
    ]).finally(() => {
      if (this.userChildRoles && !this.userChildRoles.includes(RoleConstants.ROLE_EVENTS_OFFICE)) {
        this.$router.push({ name: '404' });
      }
      this.setItemList();
      this.$loading.clear(loading);
    })
    this.$store.commit('event/RESET_NEW_CHILD_EVENT');
    this.$store.commit('event/RESET_CHILD_EVENT_DETAIL');
    this.$store.commit('event/RESET_ERROR_FIELDS');
  },
};
</script>

<style scoped>
.mr-8 {
  margin-right: 8px;
}
</style>
